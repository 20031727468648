import { Link } from "@reach/router";
import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TextContent from "../components/shared/TextContent";
import { ID as artShareHeaderId } from "../components/social/MailingListForm";
import monkeyspin from "../images/pixel/monkeyspin.webp";

const Animation = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
`;

const Banner = styled.header`
  height: 40px;
  width: 100%;
`;

export default function MonkeySpinPage() {
  return (
    <Layout>
      <SEO title="Pixel Art" />
      <Banner />
      <TextContent>
        <h1>Monkey Spin</h1>
        <p>
          Grab fruits and avoid the nasty insects! This fast-paced mobile game
          combines daringly cute pixel art with a fun physics-based movement
          mechanic.
        </p>
        <p>Moneky Spin is still under development.</p>
        <p>
          Sign up to <a href={`#${artShareHeaderId}`}>my art share</a> to
          receive updates!
        </p>
        <Animation src={monkeyspin} alt="Monkey Spin" />
        <p>
          More <Link to="/pixel-art">pixel art</Link>
        </p>
      </TextContent>
    </Layout>
  );
}
