import styled from "styled-components";

const TextContent = styled.article`
  margin: auto;
  max-width: 720px;
  font-size: 1.1em;
  line-height: 1.5;

  p {
    text-align: justify;
  }
`;

export default TextContent;
